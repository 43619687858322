import React from 'react'
import { useAutofocus } from 'react-autofocus'
import { every, some } from 'lodash'
import { DateTime } from 'luxon'
import { safeParseInt } from 'ytil'
import { forwardRef } from '~/ui/component'
import {
  Center,
  ClearButton,
  DatePickerDialog,
  HBox,
  Label,
  SVG,
  Tappable,
  TextFieldProps,
  VBox,
} from '~/ui/components'
import { ChangeCallback, FieldChangeCallback, invokeFieldChangeCallback } from '~/ui/form'
import { useBoolean, useCompoundFocus, useRefMap } from '~/ui/hooks'
import { colors, createUseStyles, layout, presets, shadows } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props extends Omit<TextFieldProps, 'value' | 'onChange' | 'onCommit'> {
  template: DateTimeComponentsFieldTemplate

  value:     DateTime | null
  onChange?: ChangeCallback<DateTime | null> | FieldChangeCallback<DateTime | null>
  onCommit?: (value: DateTime | null) => any

  utc?:    boolean
  picker?: boolean

  inputRef?:   React.Ref<HTMLInputElement>
  inputStyle?: TextFieldProps['inputStyle']
}

export type DateTimeComponentsFieldTemplate = DateTimeComponentsFieldTemplatePart[]

export type DateTimeComponentsFieldTemplatePart =
  | {component: DateTimeComponent}
  | {separator: string}

export type DateTimeComponent = 'day' | 'month' | 'year' | 'hour' | 'minute'
export const DateTimeComponent: {
  prev: (component: DateTimeComponent) => DateTimeComponent | null
} = {
  prev: (component: DateTimeComponent) => {
    switch (component) {
      case 'year':  return null
      case 'month': return 'year'
      case 'day':   return 'month'
      case 'hour':  return 'minute'
      case 'minute': return 'hour'
    }
  },
}

interface DateTimeComponentsField {
  focus(component?: DateTimeComponent): void
  blur(component?: DateTimeComponent): void
  openPicker(): void
}

const DateTimeComponentsField = forwardRef('DateTimeComponentsField', (props: Props, ref: React.Ref<DateTimeComponentsField>) => {

  const {
    template,
    value: unconvertedValue,
    onChange,
    onCommit,
    utc,
    enabled  = true,
    readOnly = false,
    picker   = false,
    showClearButton = 'never',
    autoFocus: props_autoFocus,
    accessoryLeft,
    accessoryRight,
    inputStyle,
    inputAttributes: {
      onFocus,
      onBlur,
      ...inputAttributes
    } = {},
    classNames,
  } = props

  const value = React.useMemo(() => {
    if (unconvertedValue == null || !utc) {
      return unconvertedValue
    } else {
      return unconvertedValue.setZone('utc')
    }
  }, [unconvertedValue, utc])

  const empty    = value == null
  const valueRef = React.useRef<DateTime | null>(value)

  const [texts, setTexts] = React.useState<Record<DateTimeComponent, string>>(getComponentTexts(value))
  const textsRef          = React.useRef(texts)

  const resetTextsTo = React.useCallback((value: DateTime | null) => {
    setTexts(textsRef.current = getComponentTexts(value))
  }, [])

  const setValue = React.useCallback((value: DateTime | null, partial: boolean) => {
    if (value?.toSeconds() === valueRef.current?.toSeconds()) { return }
    valueRef.current = value
    if (!partial) {
      resetTextsTo(value)
    }

    invokeFieldChangeCallback(onChange, value, partial)
  }, [onChange, resetTextsTo])

  const componentsInUse = React.useMemo(() => {
    return template
      .filter(comp => 'component' in comp)
      .map(comp => (comp as any).component as DateTimeComponent)
  }, [template])

  const inputRefs = useRefMap<DateTimeComponent, HTMLInputElement>()

  const deriveDateTimeFromTexts = React.useCallback((texts: Record<DateTimeComponent, string>) => {
    if (every(componentsInUse, comp => texts[comp].trim() === '')) {
      return null
    }

    const componentValues = componentsInUse.reduce((values, component) => ({
      ...values,
      [component]: safeParseInt(texts[component]),
    }), {})

    let dateTime = DateTime.local().set(componentValues)
    if (utc) {
      dateTime = dateTime.toUTC(undefined, {keepLocalTime: true})
    }

    return dateTime.startOf('minute')
  }, [componentsInUse, utc])

  //------
  // Accessory

  const accessoryLeftRef  = React.useRef<HTMLDivElement | null>(null)
  const accessoryRightRef = React.useRef<HTMLDivElement | null>(null)

  const [accessoryLeftWidth, setAccessoryLeftWidth] = React.useState<number>(0)
  const [accessoryRightWidth, setAccessoryRightWidth] = React.useState<number>(0)

  const accessoryPadding = React.useMemo(() => {
    const paddingLeft  = Math.max(accessoryLeftWidth, presets.fieldPadding.horizontal)
    const paddingRight = Math.max(accessoryRightWidth, presets.fieldPadding.horizontal)
    return {paddingLeft, paddingRight}
  }, [accessoryLeftWidth, accessoryRightWidth])

  React.useLayoutEffect(() => {
    const accessoryLeft  = accessoryLeftRef.current
    const accessoryRight = accessoryRightRef.current

    setAccessoryLeftWidth(accessoryLeft?.getBoundingClientRect().width ?? 0)
    setAccessoryRightWidth(accessoryRight?.getBoundingClientRect().width ?? 0)
  }, [])

  //------
  // Focus & blur

  const handleComponentBlur = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const texts = textsRef.current

    const value = deriveDateTimeFromTexts(texts)
    setValue(value, true)
  }, [deriveDateTimeFromTexts, setValue, textsRef])

  const handleBlur = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const texts = textsRef.current

    const value = deriveDateTimeFromTexts(texts)
    setValue(value, false)

    onBlur?.(event)
    if (props.commitOnBlur) {
      onCommit?.(value)
    }
  }, [deriveDateTimeFromTexts, onBlur, onCommit, props.commitOnBlur, setValue, textsRef])

  const {focused, focus, blur, preventBlur, handlers} = useCompoundFocus(inputRefs, {
    selectOnFocus:   true,
    onFocus:         onFocus,
    onBlur:          handleBlur,
    onComponentBlur: handleComponentBlur,
  })

  const autoFocus = React.useCallback(() => {
    if (!props_autoFocus) { return }
    focus()
  }, [focus, props_autoFocus])

  useAutofocus(autoFocus)

  const handleMouseDown = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (some(inputRefs.all(), el => event.target === el)) { return }

    if (focused != null) {
      preventBlur()
    } else {
      focus()
    }
  }, [focus, focused, inputRefs, preventBlur])

  //------
  // Change handling

  const stepUp = React.useCallback((component: DateTimeComponent) => {
    const currentValue = valueRef.current
    if (currentValue == null) { return }

    let value = currentValue[component] + 1
    if ((component === 'day' && currentValue.daysInMonth < value) || (component === 'month' && value > 12)) {
      value = 1
    } else if ((component === 'hour' && value > 23) || (component === 'minute' && value > 59)) {
      value = 0
    }
    setValue(currentValue.set({[component]: value}), false)
    setTimeout(() => { focus(component) }, 0)
  }, [focus, setValue, valueRef])

  const stepDown = React.useCallback((component: DateTimeComponent) => {
    const currentValue = valueRef.current
    if (currentValue == null) { return }

    let value = currentValue[component] - 1
    if (component === 'day' && value < 1) {
      value = currentValue.daysInMonth
    } else if (component === 'month' && value < 1) {
      value = 12
    } else if (component === 'hour' && value < 0) {
      value = 23
    } else if (component === 'minute' && value < 0) {
      value = 59
    }

    setValue(currentValue.set({[component]: value}), false)
    setTimeout(() => { focus(component) }, 0)
  }, [focus, setValue, valueRef])

  const clear = React.useCallback(() => {
    setTexts(textsRef.current = getComponentTexts(null))
  }, [])

  React.useEffect(() => {
    const prevValueOf = valueRef.current?.valueOf()
    const nextValueOf = value?.valueOf()
    if (prevValueOf === nextValueOf) { return }

    resetTextsTo(value)
    valueRef.current = value
  }, [resetTextsTo, value])

  //------
  // Date picker

  const [datePickerOpen, openDatePicker, closeDatePicker] = useBoolean()

  const selectDatePicker = React.useCallback((date: DateTime | null) => {
    invokeFieldChangeCallback(onChange, date, true)
    closeDatePicker()
    if (componentsInUse.includes('hour')) {
      focus('hour')
    }
  }, [closeDatePicker, componentsInUse, focus, onChange])

  //------
  // Keyboard handling

  const lastKeyRef = React.useRef<string | null>(null)

  const componentFromElement = React.useCallback((element: Element): DateTimeComponent | null => {
    for (const entry of inputRefs.entries()) {
      if (entry[1] === element) {
        return entry[0]
      }
    }

    return null
  }, [inputRefs])

  const indexOfComponentInTemplate = React.useCallback((component: DateTimeComponent) => {
    return template.findIndex(part => {
      if (!('component' in part)) { return false }
      return part.component === component
    })
  }, [template])

  const findNextSeparator = React.useCallback((component: DateTimeComponent) => {
    const index = indexOfComponentInTemplate(component)
    if (index < 0 || index >= template.length - 1) { return null }

    if (!('separator' in template[index + 1])) { return null }
    return (template[index + 1] as any).separator
  }, [indexOfComponentInTemplate, template])

  const findNextComponent = React.useCallback((component: DateTimeComponent) => {
    let index = indexOfComponentInTemplate(component)
    if (index < 0) { return null }

    while (++index < template.length) {
      if ('component' in template[index]) {
        return (template[index] as any).component
      }
    }

    return null
  }, [indexOfComponentInTemplate, template])

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const component = componentFromElement(event.currentTarget)
    if (component == null) { return null }

    const maxChars  = component === 'year' ? 4 : 2
    const text      = event.target.value
    const cleanText = text.replace(/[^0-9]/g, '').slice(0, maxChars)

    const nextSeparator = findNextSeparator(component)
    const hasSeparator  = nextSeparator != null && text.includes(nextSeparator)

    // Update the text.
    const nextTexts = {
      ...textsRef.current,
      [component]: cleanText,
    }
    setTexts(textsRef.current = nextTexts)

    // Move to the next component if a hyphen was typed, or if the current box is filled.
    if (hasSeparator || (lastKeyRef.current !== 'Backspace' && cleanText.length >= maxChars)) {
      const next = findNextComponent(component)
      if (next) {
        focus(next)
      }
    }
  }, [componentFromElement, findNextComponent, findNextSeparator, focus, textsRef])

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    lastKeyRef.current = event.key

    const component = componentFromElement(event.currentTarget)
    if (component == null) { return null }

    if (event.key === 'ArrowUp') {
      stepUp(component)
      event.preventDefault()
    } else if (event.key === 'ArrowDown') {
      stepDown(component)
      event.preventDefault()
    } else if (event.key === 'Space') {
      openDatePicker()
      event.preventDefault()
    } else if (event.key === 'Backspace' && textsRef.current[component] === '') {
      const prev = DateTimeComponent.prev(component)
      if (prev != null) {
        focus(prev)
      }
      event.preventDefault()
    }
  }, [focus, openDatePicker, componentFromElement, stepDown, stepUp, textsRef])

  //------
  // Imperative handle

  React.useImperativeHandle(ref, () => ({
    focus,
    blur,
    openPicker: openDatePicker,
  }))

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const allClassNames = [
      $.DateTimeComponentsField,
      {disabled: !enabled, readOnly},
      inputStyle,
      classNames,
    ]

    const style: React.CSSProperties = {
      ...accessoryPadding,
    }

    return (
      <HBox align='stretch' gap={layout.padding.s} classNames={allClassNames} style={style} onMouseDown={handleMouseDown}>
        {renderAccessoryLeft()}
        <HBox flex='grow'>
          {renderDate()}
        </HBox>
        {picker && renderCalendarButton()}
        {picker && renderDatePickerDialog()}
        {renderAccessoryRight()}
        {renderClearButton()}
      </HBox>
    )
  }

  function renderDate() {
    return (
      <HBox classNames={$.date} gap={layout.padding.inline.xs}>
        {template.map(renderTemplatePart)}
      </HBox>
    )
  }

  function renderTemplatePart(part: DateTimeComponentsFieldTemplatePart, index: number) {
    if ('component' in part) {
      return renderInput(part.component)
    } else {
      return <span key={index}>{part.separator}</span>
    }
  }

  function renderInput(component: DateTimeComponent) {
    return renderSteppersWithInput(component, texts[component] !== '', (
      <input
        classNames={[$.input, component]}
        type='text'
        value={texts[component]}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={inputRefs.for(component)}
        disabled={!enabled}
        {...handlers}
        {...inputAttributes}
      />
    ))
  }

  function renderSteppersWithInput(component: DateTimeComponent, showSteppers: boolean, input: React.ReactNode) {
    if (readOnly) { return null }

    const stepPartUp = stepUp.bind(null, component)
    const stepPartDown = stepDown.bind(null, component)

    return (
      <VBox key={component} gap={-10} align='center'>
        {showSteppers && (
          <Tappable classNames={$.stepper} onTap={stepPartUp} tabIndex={-1}>
            <SVG name='chevron-up' size={{width: 8, height: 8}} dimmer/>
          </Tappable>
        )}
        {input}
        {showSteppers && (
          <Tappable classNames={$.stepper} onTap={stepPartDown} tabIndex={-1}>
            <SVG name='chevron-down' size={{width: 8, height: 8}} dimmer/>
          </Tappable>
        )}
      </VBox>
    )
  }

  function renderClearButton() {
    if (showClearButton === 'never') { return null }
    if (showClearButton === 'notempty' && empty) { return null }
    if (readOnly) { return null }

    return (
      <Tappable classNames={[$.clearButton, {disabled: empty}]} showFocus={false} focusable={false} onTap={clear} enabled={!empty}>
        <SVG name='cross' size={layout.icon.m} dim/>
      </Tappable>
    )
  }

  function renderAccessoryLeft() {
    if (accessoryLeft == null) { return null }

    const children = isReactText(accessoryLeft)
      ? <Label>{accessoryLeft}</Label>
      : accessoryLeft

    return (
      <Center ref={accessoryLeftRef} classNames={[$.accessory, $.accessoryLeft]}>
        {children}
      </Center>
    )
  }

  function renderAccessoryRight() {
    if (accessoryRight == null) { return null }

    const children = isReactText(accessoryRight)
      ? <Label>{accessoryRight}</Label>
      : accessoryRight

    return (
      <Center ref={accessoryRightRef} classNames={[$.accessory, $.accessoryRight]}>
        {children}
      </Center>
    )
  }

  function renderCalendarButton() {
    return (
      <ClearButton
        icon='calendar'
        onTap={openDatePicker}
        focusable={false}
      />
    )
  }

  function renderDatePickerDialog() {
    return (
      <DatePickerDialog
        open={datePickerOpen}
        requestClose={closeDatePicker}
        onSelect={selectDatePicker}
        autoCommit={true}
      />
    )
  }

  return render()

})

export default DateTimeComponentsField

function getComponentTexts(value: DateTime | null) {
  return {
    year:   value?.toFormat('yyyy') ?? '',
    month:  value?.toFormat('MM') ?? '',
    day:    value?.toFormat('dd') ?? '',
    hour:   value?.toFormat('HH') ?? '',
    minute: value?.toFormat('mm') ?? '',
  }
}

const useStyles = createUseStyles(theme => ({
  DateTimeComponentsField: {
    ...presets.field(theme),
    overflow: 'hidden',
    padding: [presets.fieldPadding.horizontal / 2, presets.fieldPadding.horizontal],
  },

  date: {
    height:       presets.fieldHeight.normal - 2 * presets.fieldPadding.horizontal / 2,
    border:       [1, 'solid', theme.bg.subtle],
    background:   theme.colors.bg.light.semi,
    borderRadius: layout.radius.s,
    padding:      layout.padding.inline.xs,

    '$DateTimeComponentsField.dark &': {
      border:       [1, 'solid', theme.bg.subtle],
      background:   theme.colors.bg.light.alt,
    },
  },

  input: {
    ...presets.clearInput(theme),
    width:     '1.6em',
    '&.year': {
      width: '3.2em',
    },
    font:      'inherit',
    minWidth:  0,
    textAlign: 'center',
  },

  stepper: {
    position: 'relative',
    padding:  [4, layout.padding.inline.s],
    '&:first-child': {
      marginTop: 1,
    },
    '&:last-child': {
      marginTop: -1,
    },

    '&:hover': {
      ...colors.overrideForeground(theme.semantic.primary),
    },
  },

  accessory: {
    position: 'absolute',
    top:      0,
    bottom:   0,
  },

  accessoryLeft: {
    left: 0,
    paddingLeft:  presets.fieldPadding.left,
    paddingRight: layout.padding.inline.m,
  },

  accessoryRight: {
    right: 0,
    paddingRight: presets.fieldPadding.right,
    paddingLeft:  layout.padding.inline.m,
  },

  clearButton: {
    ...layout.flex.center,

    margin: [
      -presets.fieldPadding.top,
      -presets.fieldPadding.right,
      -presets.fieldPadding.bottom,
      presets.fieldPadding.right,
    ],

    padding:    [0, presets.fieldPadding.horizontal],
    background: theme.colors.bg.light.semi,

    borderTopRightRadius:    presets.fieldBorderRadius,
    borderBottomRightRadius: presets.fieldBorderRadius,

    '&:not(.disabled):hover': {
      ...colors.overrideForeground(theme.semantic.primary),
    },
    '&:not(.disabled):focus': {
      ...colors.overrideForeground(theme.semantic.primary),
      ...shadows.focus.subtle(theme),
    },
    '&.disabled': {
      opacity: 0.6,
      ...colors.overrideForeground(theme.fg.dimmer),
    },
  },
}))
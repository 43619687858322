import React from 'react'
import { DateTime } from 'luxon'
import { objectEquals } from 'ytil'
import { memo } from '~/ui/component'
import { AutoGrid, Center, Dimple, Label, Panel, Tappable } from '~/ui/components'
import { colors, createUseStyles, layout, shadows, useStyling } from '~/ui/styling'
import {
  cellSize,
  dateCellAnnotationSize,
  height,
  padding,
  selectionCircleSize,
  width,
} from './layout'
import { CalendarAnnotation } from './types'

interface Props {
  month:     DateTime
  goToMonth: (dateTime: DateTime) => any

  selectedDate:     DateTime | null
  onSelectDateTime: (dateTime: DateTime, commit: boolean) => any

  weekStart:   number
  annotations: CalendarAnnotation[]
  utc:         boolean
  enabled:     boolean
}

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
  const {
    month:        prevMonth,
    selectedDate: prevSelectedDate,
    ...prevRest
  } = prevProps
  const {
    month:        nextMonth,
    selectedDate: nextSelectedDate,
    ...nextRest
  } = nextProps

  if (!prevMonth.startOf('day').equals(nextMonth.startOf('day'))) { return false }
  if (prevSelectedDate == null && nextSelectedDate != null) { return false }
  if (prevSelectedDate != null && nextSelectedDate == null) { return false }
  if (prevSelectedDate != null && nextSelectedDate != null && !prevSelectedDate.startOf('day').equals(nextSelectedDate.startOf('day'))) { return false }
  if (!objectEquals(prevRest, nextRest)) { return false }

  return true
}

const MonthCalendar = memo('MonthCalendar', (props: Props) => {

  const {month, selectedDate, onSelectDateTime, goToMonth, weekStart, annotations, utc, enabled} = props

  let now = DateTime.local()
  if (utc) { now = now.toUTC(undefined, {keepLocalTime: true}) }

  const monthStart = month.startOf('month')
  const monthEnd   = month.endOf('month')

  let startYear = monthStart.year
  const startWeek = monthStart.weekNumber
  let weekCount = monthEnd.weekNumber - monthStart.weekNumber + 1

  if (monthEnd.weekNumber === 1) {
    weekCount = month.weeksInWeekYear - monthStart.weekNumber + monthEnd.weekNumber + 1
  } else if (startWeek >= 52) {
    startYear -= 1
    weekCount = monthEnd.weekNumber - (52 - monthStart.weekNumber) + 1
  }

  const start = DateTime.fromObject({
    weekYear:   startYear,
    weekNumber: startWeek,
    weekday:    weekStart,
    zone:       utc ? 'utc' : undefined,
  }).startOf('day')

  const {colors} = useStyling()

  //------
  // Render

  const $ = useStyles()

  function render() {
    return (
      <Panel semi={false} classNames={$.monthCalendar} gap={layout.padding.inline.s}>
        {renderHeader()}
        <Dimple horizontal classNames={$.dimple}/>
        <AutoGrid
          rows={weekCount}
          columns={7}
          children={renderDateCell}
        />
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <AutoGrid
        rows={1}
        columns={7}
        children={renderWeekDayCell}
      />
    )
  }

  function renderWeekDayCell(_: number, column: number) {
    let weekday = weekStart + column
    if (weekday < 1) { weekday += 7 }

    const dateTime = DateTime.fromObject({weekday})
    return (
      <Center classNames={$.weekDayCell}>
        <Label small bold dim>
          {dateTime.toFormat('ccc')}
        </Label>
      </Center>
    )
  }

  function renderDateCell(row: number, column: number) {
    const dateTime    = start.plus({days: row * 7 + column})
    const classNames  = dateCellClassNames(dateTime)
    const tap         = handleDateCellTap.bind(null, dateTime, false)
    const doubleClick = handleDateCellTap.bind(null, dateTime, true)

    const cellAnnotations = annotations.filter(a => a.date.valueOf() === dateTime.valueOf())

    return (
      <Tappable classNames={classNames} onTap={tap} enabled={enabled} onDoubleClick={doubleClick} noFeedback>
        <Center flex>
          <Label align='center' small>
            {dateTime.toFormat('d')}
          </Label>
        </Center>
        {cellAnnotations.map(renderAnnotation)}
      </Tappable>
    )
  }

  function renderAnnotation(annotation: CalendarAnnotation, index: number) {
    return (
      <Center key={index} classNames={$.dateCellAnnotation}>
        {annotation.render != null ? (
          annotation.render(annotation.date)
        ) : (
          <div
            classNames={$.defaultDateCellAnnotation}
            style={{background: (annotation.color ?? colors.semantic.primary).string()}}
          />
        )}
      </Center>
    )
  }

  const dateCellClassNames = React.useCallback((dateTime: DateTime): React.ClassNamesProp => {
    return [
      $.cell,
      {selected: selectedDate != null && dateTime.hasSame(selectedDate, 'day')},
      {today:    dateTime.hasSame(now, 'day')},
      {outside:  dateTime < monthStart || dateTime > monthEnd},
    ]
  }, [$.cell, monthEnd, monthStart, now, selectedDate])

  const handleDateCellTap = React.useCallback((dateTime: DateTime, commit: boolean) => {
    if (commit) {
      onSelectDateTime(dateTime, true)
    } else {
      if (dateTime < monthStart) {
        goToMonth(month.plus({months: -1}))
      } else if (dateTime > monthEnd) {
        goToMonth(month.plus({months: 1}))
      }
      onSelectDateTime(dateTime, false)
    }
  }, [goToMonth, month, monthEnd, monthStart, onSelectDateTime])

  return render()

}, propsAreEqual)

export default MonthCalendar

const useStyles = createUseStyles(theme => ({
  monthCalendar: {
    width,
    height,
    padding,
  },

  dimple: {
    margin: [0, -padding],
  },

  weekDayCell: {
    width:         cellSize,
    color:         theme.colors.fg.dark.dim,
    paddingBottom: layout.padding.inline.s,
  },

  cell: {
    position: 'relative',
    width:    cellSize,
    height:   cellSize,

    '& > *': {
      position: 'relative',
    },
    '&::before': {
      content: '""',

      position:     'absolute',
      width:        selectionCircleSize.date,
      height:       selectionCircleSize.date,
      left:         (cellSize - selectionCircleSize.date) / 2,
      top:          (cellSize - selectionCircleSize.date) / 2,
      borderRadius: selectionCircleSize.date / 2,
    },

    '&:hover::before': {
      background: theme.semantic.primary.alpha(0.15),
    },

    '&:focus::before': {
      boxShadow: shadows.focus.bold(theme),
    },

    '&.outside': {
      opacity: 0.2,
    },
    '&.selected': {
      '&::before': {
        background: theme.semantic.primary,
      },
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.primary)),
    },
    '&.today': {
      '&::before': {
        boxShadow: [0, 0, 0, 1.5, theme.semantic.secondary],
      },
      '& *': {
        fontWeight: 'bold',
      },
    },
  },

  dateCellAnnotation: {
    position: 'absolute',
    top:      dateCellAnnotationSize / 2,
    right:    dateCellAnnotationSize / 2,
  },

  defaultDateCellAnnotation: {
    width:        dateCellAnnotationSize,
    height:       dateCellAnnotationSize,
    borderRadius: dateCellAnnotationSize / 2,
  },
}))
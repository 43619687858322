import React from 'react'
import { chatStore } from '~/stores'
import ChatContextProvider from '~/ui/app/chat/ChatContext'
import renderFeedbackBubble from '~/ui/app/chat/feedback'
import { observer } from '~/ui/component'
import { Label, Panel, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import GroupFeedbackBubblePreview from './GroupFeedbackBubblePreview'
import ScriptMessageFeedbackFormModel from './ScriptMessageFeedbackFormModel'

export interface Props {
  formModel: ScriptMessageFeedbackFormModel
}

const ScriptMessageFeedbackPreview = observer('ScriptMessageFeedbackPreview', (props: Props) => {

  const {formModel} = props

  const {t} = useResourceTranslation('script-messages')
  const feedback = formModel?.buildMessageFeedback()

  // Without this, the feedback bubbles wil throw an error
  const dummyChatService = React.useMemo(() => {
    return chatStore.chatService('123', 'channel:123')
  }, [])

  //------
  // Rendering

  function render() {
    if (feedback == null) { return null }

    return (
      <ChatContextProvider service={dummyChatService}>
        <VBox gap={layout.padding.s}>
          <Label small dim>
            {t('feedback.preview')}
          </Label>
          <Panel semi={false} padding={layout.padding.s} align='right'>
            {feedback.type === 'group' ? (
              // Group feedback is rendered as ChoiceFeedback, so we need to a custom component to preview it
              <GroupFeedbackBubblePreview feedback={feedback} />
            ) : (
              renderFeedbackBubble(null, feedback)
            )}
          </Panel>
        </VBox>
      </ChatContextProvider>
    )
  }

  return render()

})

export default ScriptMessageFeedbackPreview
import React from 'react'
import { ColorResult, RGBColor, SketchPicker } from 'react-color'
import { useTranslation } from 'react-i18next'
import Color from 'color'
import { forwardRef } from '~/ui/component'
import {
  Center,
  ClearButton,
  ColorBullet,
  ColorButton,
  HBox,
  Label,
  Popup,
  PopupProps,
  SVG,
  Tappable,
  VBox,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, shadows, useStyling, useTheme } from '~/ui/styling'

export interface Props {
  value:     Color | null
  onChange?: (value: Color | null) => boolean | any

  renderTarget?: (color: Color | null) => React.ReactNode
  allowClear?:   boolean
  allowAlpha?:   boolean

  presetsCaption?: string
  presetColors?:   Color[]

  invalid?:      boolean
  icon?:         SVGName

  popupProps?: Omit<PopupProps,
    | 'open'
    | 'requestClose'
    | 'renderContent'
    | 'targetClassNames'
    | 'shim'
    | 'classNames'
    | 'children'>

  classNames?:  React.ClassNamesProp
}

interface ColorField {
  open():  void
  close(): void
}

const ColorField = forwardRef('ColorField', (props: Props, ref: React.Ref<ColorField>) => {

  const {colors} = useStyling()
  const theme = useTheme()
  const {
    value,
    onChange,
    renderTarget,
    presetsCaption,
    presetColors = [],
    allowClear = true,
    allowAlpha = true,
    icon,
    popupProps,
    classNames,
  } = props

  const [isOpen, open, close] = useBoolean()

  const [tempValue, setTempValue] = React.useState<RGBColor | null>(null)

  const [t] = useTranslation('color_field')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Popup
        open={isOpen}
        requestClose={close}
        renderContent={renderPopup}
        targetClassNames={classNames}
        shim={true}
        classNames={$.popup}
        children={renderButton()}
        {...popupProps}
      />
    )
  }

  function renderButton() {
    return (
      <Tappable onTap={open}>
        {(renderTarget ?? renderColorWell)(value)}
      </Tappable>
    )
  }

  function renderColorWell() {
    return (
      <Center classNames={$.colorWell}>
        {renderSwatch()}
        {renderIcon()}
      </Center>
    )
  }

  function renderSwatch() {
    if (value == null) { return null }

    return (
      <ColorBullet
        color={value}
      />
    )
  }

  function renderIcon() {
    if (icon == null) { return null }

    return (
      <Center classNames={$.icon}>
        <SVG
          name={icon}
          size={layout.icon.m}
          color={theme.fg.dim}
        />
      </Center>
    )
  }

  function renderPopup() {
    return (
      <VBox>
        {renderPresets()}
        {renderPicker()}
        {renderFooter()}
      </VBox>
    )
  }

  function renderPresets() {
    if (presetColors.length === 0) { return null }

    return (
      <VBox classNames={$.presetColors} gap={layout.padding.inline.m}>
        <Label caption small dim>
          {presetsCaption ?? t('presets')}
        </Label>
        <HBox wrap gap={layout.padding.inline.m}>
          {presetColors.map(color => (
            <ColorButton
              key={color.hex()}
              color={color}
              onTap={onChange?.bind(null, color)}
            />
          ))}
        </HBox>
      </VBox>
    )
  }

  function renderFooter() {
    if (!allowClear) { return null }

    return (
      <VBox classNames={$.popupFooter}>
        <ClearButton
          icon='cross-circle'
          caption={t('buttons:clear')}
          color={colors.semantic.negative}
          onTap={clear}
          align='center'
          small
        />
      </VBox>
    )
  }

  function renderPicker() {
    return (
      <SketchPicker
        color={rgbColor}
        onChange={handleChange}
        onChangeComplete={handleChangeComplete}
        disableAlpha={!allowAlpha}
      />
    )
  }

  const rgbColor = React.useMemo(() => {
    if (tempValue != null) {
      return tempValue
    } else if (value != null) {
      return colorToRGBColor(value)
    } else {
      return 'transparent'
    }
  }, [tempValue, value])

  const handleChange = React.useCallback((color: ColorResult) => {
    setTempValue(color.rgb)
  }, [])

  const clear = React.useCallback(() => {
    setTempValue(null)
    onChange?.(null)
    close()
  }, [close, onChange])

  const handleChangeComplete = React.useCallback((color: ColorResult) => {
    setTempValue(null)
    const result = onChange?.(rgbColorToColor(color.rgb))
    if (result === true) {
      close()
    }
  }, [close, onChange])

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }), [close, open])

  return render()

})

export default ColorField

function colorToRGBColor(color: Color): RGBColor {
  return {
    r: color.red(),
    g: color.green(),
    b: color.blue(),
    a: color.alpha(),
  }
}

function rgbColorToColor(color: RGBColor): Color {
  return new Color([
    color.r,
    color.g,
    color.b,
    color.a ?? 1,
  ])
}

export const size = {
  width:  32,
  height: 32,
}

const useStyles = createUseStyles(theme => ({
  popup: {
    minWidth: '0 !important',
    width:    220,

    '& .sketch-picker': {
      border:       'none !important',
      borderRadius: '0 !important',
      boxShadow:    'none !important',
    },

    '& input': {
      width: '100% !important',
    },
  },

  presetColors: {
    padding: layout.padding.inline.m,
  },

  popupFooter: {
    padding: layout.padding.inline.m,
  },

  colorWell: {
    ...size,
    borderRadius: size.height / 2,
    background:   theme.bg.alt,
    boxShadow:    ['inset', 1, 1, 5, 0, shadows.shadowColor],

    position: 'relative',
  },

  icon: {
    ...layout.overlay,
  },
}))
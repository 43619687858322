import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Group, ParticipantVisibility, Project } from '~/models'
import { dataStore, projectStore, SubmitResult } from '~/stores'
import { observer } from '~/ui/component'
import {
  Chip,
  ClearButton,
  HBox,
  Label,
  Panel,
  SelectField,
  SwitchField,
  TextBlock,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import {
  FormDialog,
  FormDialogProps,
  FormField,
  FormModel,
  translateFormErrorPaths,
} from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import { TagsField } from '../../tags'

export interface Props {
}

const ParticipantVisibilitySummary = observer('ParticipantVisibility', (props: Props) => {

  const {t} = useResourceTranslation('projects')

  const {project} = projectStore
  const config    = project?.participantVisibility

  const [formOpen, openForm, closeForm] = useBoolean()
  const formModel = React.useMemo(() => {
    return project == null ? null : new ParticipantVisibilityFormModel(project)
  }, [project])

  //------
  // Rendering

  function render() {
    if (config == null) { return null }

    return (
      <>
        <HBox gap={layout.padding.s}>
          <VBox align='left' flex gap={layout.padding.inline.m}>
            <TextBlock small dim>
              {t(`fields.participant_visibility.summary.${config.type === 'global' ? config.value : 'tag'}`)}
            </TextBlock>
            {config.type === 'tag' && (
              <Chip>
                {config.tag}
              </Chip>
            )}
          </VBox>
          <ClearButton
            icon='pencil'
            onTap={openForm}
          />
        </HBox>
        {formModel != null && (
          <ParticipantVisibilityForm
            model={formModel}
            requestClose={closeForm}
            open={formOpen}
            title={t('fields.participant_visibility.caption')}
            headerRight='$close'
            icon='eye'
          />
        )}
      </>
    )
  }

  return render()

})

export default ParticipantVisibilitySummary

interface ParticipantVisibilityForm extends FormDialogProps<ParticipantVisibilityFormModel> {}

const ParticipantVisibilityForm = observer('ParticipantVisibilityForm', (props: ParticipantVisibilityForm) => {

  const {model} = props

  const {t} = useResourceTranslation('projects')

  const typeChoices = React.useMemo((): Choice<ParticipantVisibility['type']>[] => [{
    value:  'global',
    caption: t('fields.participant_visibility.available_types.global'),
  }, {
    value:  'tag',
    caption: t('fields.participant_visibility.available_types.tag'),
  },
  ], [t])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog {...props}>
        {renderInstruction()}
        <FormField name='type' i18nKey='participant_visibility.type'>
          {bind => <SelectField choices={typeChoices} {...bind} />}
        </FormField>
        {model.type === 'global' ? renderGlobal() : renderTag()}
      </FormDialog>
    )
  }

  function renderInstruction() {
    return (
      <Panel padding={layout.padding.m}>
        <TextBlock small dim>
          {t('fields.participant_visibility.instruction')}
        </TextBlock>
      </Panel>
    )
  }

  function renderGlobal() {
    return (
      <FormField name='value' i18nKey='participant_visibility.value'>
        {bind => (
          <SwitchField
            {...bind}
            label={{
              on: t('fields.participant_visibility.summary.true'),
              off: t('fields.participant_visibility.summary.false'),
            }}
          />
        )}
      </FormField>
    )
  }

  function renderTag() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <FormField name='tag' i18nKey='participant_visibility.tag'>
          {bind => (
            <TagsField
              {...bind}
              multi={false}
              Model={Group}
            />
          )}
        </FormField>
        <Label dim small>
          {t('fields.participant_visibility.summary.tag')}
        </Label>
      </VBox>
    )
  }

  return render()

})

class ParticipantVisibilityFormModel implements FormModel {

  constructor(
    private readonly project: Project,
  ) {
    const defaults = project.participantVisibility
    this.type = defaults.type
    if (defaults.type === 'global') {
      this.value = defaults.value
      this.tag   = null
    } else {
      this.value = true
      this.tag   = defaults.tag
    }
    makeObservable(this)
  }

  @observable
  public type: 'global' | 'tag'

  @observable
  public value: boolean

  @observable
  public tag: string | null

  public async submit(): Promise<SubmitResult | undefined> {
    const result = await dataStore.update(Project, this.project.id, {
      participantVisibility: this.buildData(),
    })
    return translateFormErrorPaths(result, path => path.replace(/^participantVisibility\./, ''))
  }

  private buildData() {
    const result: Record<string, any> = {
      type: this.type,
    }
    if (result.type === 'global') {
      result.value = this.value
    } else {
      result.tag = this.tag
    }

    return result
  }

}